<!--系统设置-菜系设置-->
<template>
<section>
  <!-- 工具条 -->
  <el-col :span='24' class='toolbar' style='padding-bottom: 0px;'>
  <el-form :inline='true' :model='filter'>
    <el-form-item>
      <el-input size='mini' v-model='filter.name' placeholder='关键字搜索'>
        <el-button slot='prepend' icon='el-icon-refresh' @click='handleRefresh'></el-button>
        <el-button slot='append' icon='el-icon-search' @click='getData'></el-button>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button type='primary' size='mini' @click='handleAdd'>新增</el-button>
    </el-form-item>
  </el-form>
  </el-col>
    <!-- 列表 -->
    <el-col :span='24'>
      <el-table :data='datalist.content' size='mini' border highlight-current-row v-loading='loading' @selection-change='selsChange' :max-height='clientHeight' style='width: 100%'>
        <el-table-column type='selection' />
        <el-table-column type='index' label='#' align='center' width='55' />
        <el-table-column prop='ResourceName' label='类型' align='center' header-align='center' />
        <el-table-column prop='Name' label='名称' align='center' header-align='center' />
        <el-table-column prop='Density' label='浓度比值(毫克/立方)' align='center' header-align='center' />
        <el-table-column label='操作' align='center' header-align='center'>
           <template slot-scope='scope'>
           <i title='编辑' @click='handleEdit(scope.$index, scope.row)' class='opt el-icon-edit' />
           <i title='删除' @click='handleDel(scope.$index, scope.row)' class='opt el-icon-delete' />
           </template>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span='24' class='toolbar'>
      <el-button type='danger' size='mini' @click='bulkRemove' :disabled='sels.length===0'>批量删除</el-button>
      <el-pagination small background @size-change='handleSizeChange' @current-change='handleCurrentChange' :current-page.sync='filter.page' :page-sizes='filter.pageSizes' layout='total, sizes, prev, pager, next, jumper' :total='datalist.total' style='display:inline-block;margin-left:15px'>
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog :title='dlg.title' :visible.sync='dlg.visible' @close='handlerClose'>
      <el-form :model='formData' label-width='140px' :rules='formRule' ref='form' size='mini'>
        <el-form-item prop='ResourceName' label='菜系类型' >
          <el-select filterable allow-create v-model="formData.ResourceName" filterable @change='hasChange'>
            <el-option v-for="(item, index) in datalist.list" :key="index" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item prop='Name' label='名称' >
          <el-input @change='hasChange' v-model.trim='formData.Name' />
        </el-form-item>
        <el-form-item prop='Density' label='浓度比值(毫克/立方)' >
          <el-input-number @change='hasChange' v-model.trim='formData.Density' />
        </el-form-item>
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button size='mini' @click.native='dlg.visible=false'>取消</el-button>
        <el-button type='primary' size='mini' @click.native='handleSubmit' >提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      changeState: false,
      index: 0, // 记录更新index
      sels: [], // 列表选中列
      loading: false,
      datalist: {},
      dlg: {
        visible: false,
        title: ''
      },
      filter: {
        name: null,
        field: 'name',
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      formData: {},
      formRule: {
        Name: [{ required: true, message: '名称不可为空', trigger: 'blur' }],
        ResourceName: [{ required: true, message: '菜系类型不可为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState(['props', 'clientHeight'])
  },
  created() {
    this.getData()
  },
  methods: {
    hasChange() {
      this.changeState = true
    },
    getData: function() {
      this.loading = true
      var para = { StartAt: (this.filter.page - 1) * this.filter.size, Size: this.filter.size }
      para.Param = {}
      if (this.filter.name) {
        para.Param[this.filter.field] = this.filter.name
      }
      this.$post('admin/listCuisine', para).then(res => {
        this.datalist = res
        this.loading = false
      })
    },
    handleRefresh() {
      this.filter.page = 1
      this.filter.name = null
      this.getData()
    },
    selsChange: function(sels) {
      this.sels = sels
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleAdd: function() {
      this.dlg.title = '新增'
      this.dlg.visible = true
      this.formData = {}
    },
    handleEdit: function(index, row) {
      this.dlg.title = '编辑'
      this.dlg.visible = true
      this.index = index
      this.formData = Object.assign({}, row)
    },
    // 批量删除
    bulkRemove: function() {
      const ids = this.sels.map(item => item.Id).toString()
      this.$confirm('确认删除选中记录吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$post('admin/bulkRemoveCuisine', { ids: ids }).then((res) => {
          this.getData()
        }).catch(() => {})
      }).catch(() => {})
    },
    handleDel: function(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$post('admin/delCuisine', { id: row.Id }).then((res) => {
          this.getData()
        }).catch(() => {})
      }).catch(() => {})
    },
    handleSubmit: function() {
      if (!this.changeState) {
        this.dlg.visible = false
        return
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            const para = Object.assign({}, this.formData)
            this.$post('admin/saveCuisine', para).then(() => {
              this.dlg.visible = false
              this.getData()
            }).catch(() => {})
          }).catch(() => {})
        }
      })
    },
    handlerClose: function() {
      if (this.changeState) {
        this.changeState = false
        this.handleRefresh()
      }
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
    }
  }
}
</script>
